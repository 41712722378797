<template>
  <MainLayout
    isFullScreen
    class="main-layout--password-recovery">
    <router-link
      :to="{ name: 'ArtistSignin' }"
      class="mr-auto">
      <Icon
        :variant="'arrow-left-outline'"
        :color="'white'"
        :size="'sm'"
        :backdropColor="'black'">
      </Icon>
    </router-link>
    <div
      v-if="hasSentEmail"
      class="password-recovery__success-wrapper">
      <Heading
        :tag="'h1'"
        :level="'h2'"
        class="mb-base">
        {{ $t('artist.passwordRecovery.mailSent') }}
      </Heading>
      <Paragraph
        isTextCentered
        :size="'md'">
        {{ email }}
      </Paragraph>
    </div>

    <template v-else>
      <form
        @submit.prevent="handleRecoveryPasswordSubmit"
        ref="form"
        class="password-recovery__form-wrapper"
        novalidate>
        <header class="password-recovery__form-header">
          <Heading
            isTextCentered
            class="mb-xs">
            {{ $t('common.forgotPassword') }}
          </Heading>
          <Paragraph isTextCentered>
            {{ $t('artist.passwordRecovery.askEmail') }}
          </Paragraph>
        </header>
        <FieldInput
          v-model="email"
          :id="'password-recovery-email'"
          :label="$t('common.email')"
          :placeholder="$t('common.email')"
          isAutofocus
          type="email"
          autocomplete="email"
          required
          class="mb-lg">
        </FieldInput>
        <Loader v-if="isLoading"></Loader>
        <Button
          v-else
          type="submit"
          class="mt-auto">
          {{ $t('common.confirm') }}
        </Button>
      </form>
    </template>
  </MainLayout>
</template>

<script>

import {
  mapMutations,
  mapActions,
}                     from 'vuex';

import MainLayout     from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader         from '../../../components/atoms/Loader/a-Loader.vue';
import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button         from '../../../components/atoms/Button/a-Button.vue';
import Icon           from '../../../components/atoms/Icon/a-Icon.vue';
import FieldInput     from '../../../components/atoms/FieldInput/a-FieldInput.vue';


export default {
  name: 'p-PasswordRecovery',
  components: {
    MainLayout,
    Loader,
    Heading,
    Paragraph,
    Button,
    Icon,
    FieldInput
  },
  props: {
    isArtist: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    email: '',
    isLoading: false,
    hasSentEmail: false,
  }),
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
    }),
    ...mapActions({
      recoverPassword: 'User/RECOVER_PASSWORD',
    }),
    async handleRecoveryPasswordSubmit() {
      const isFormValid = this.$refs.form.checkValidity();

      if (!isFormValid) return;

      try {
        this.isLoading = true;

        const input = {
          input: {
            Email: this.email,
            IsArtist: this.isArtist,
          },
        };

        this.hasSentEmail = await this.recoverPassword(input);
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.password-recovery {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--space-header-height);

    @media screen and ($desktop) {
      min-width: 450px;
      max-width: 450px;
    }
  }

  &__form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--space-lg);
  }

  &__success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

</style>